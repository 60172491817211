.Testimonials {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
}

.left-t {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1 1;
  text-transform: uppercase;
  color: #fff;
}

.left-t > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}

.left-t > :nth-child(2),
.left-t > :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
}

.testimonialData-name {
  color: var(--orange);
}

.left-t > :nth-child(4) {
  text-transform: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}

.right-t {
  flex: 1;
  position: relative;
}

.right-t > img {
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
}

.right-t > :nth-child(1) {
  position: absolute;
  width: 17rem;
  height: 20rem;
  border: 2px solid var(--orange);
  background-color: transparent;
  right: 9rem;
  top: 0.9rem;
}

.right-t > :nth-child(2) {
  position: absolute;
  width: 17rem;
  height: 19rem;
  right: 7rem;
  top: 4rem;
  background: var(--planCard);
}

.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  left: 3rem;
}

.arrows > img {
  width: 1.5rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Testimonials {
    flex-direction: column;
  }

  .left-t > :nth-child(2),
  .left-t > :nth-child(3) {
    font-size: xx-large;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    }

    .right-t>div{
        position: relative;
        display: none;
    }

    .right-t>img{
        top: 0rem;
        right: 0rem;
        position: relative;
        align-self: center;
    }

    .right-t> :last-child{
        display: block;
        bottom: 0;
        left:0;
    }
}
